import { EDIT_USER_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/modalKeys';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const EditUserMenuItem = ({ user, organization }) => {
  const editUserModalContext = useWcpModalContext(EDIT_USER_MODAL_KEY);
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn
        onClick={() => {
          editUserModalContext.modalActions.openModal({ user, organization });
        }}
      >
        Edit
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EditUserMenuItem;
