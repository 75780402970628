import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const EnableDisablePartnershipMenuItem = ({ partnership, onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>{`${
        partnership.enabled ? 'Disable' : 'Enable'
      } Partnership`}</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EnableDisablePartnershipMenuItem;
