import { subscriptionTypeDropdownOptions } from 'legacy/features/billing/data/ui/dropdown/subscriptionTypeDropdownOptions';
import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v4/FilteredDropdown';
import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';
import React from 'react';

const SubscriptionTypeDropdown = ({
  formikProps,
  subscriptionType,
  disabled,
  setSelectedSubscriptionType,
}) => {
  useMakeFormikField({
    fieldName: 'subscriptionType',
    fieldValue: subscriptionType,
  });

  return (
    <>
      <FilteredDropdown
        fieldLabel={'Subscription Type'}
        domId={'subscriptionTypeDropdown'}
        placeholderLabel="Select a Type..."
        styleOptions={{
          // disable if no selected vsg, no subscription type, or subscription type is not unallocated
          disabled,
          showClearSelectionButton: true,
          narrowWidth: false,
          fullWidth: true,
        }}
        setup={{
          dropdownOptions: subscriptionTypeDropdownOptions,
          preselectedValue: subscriptionType,
        }}
        eventHandlers={{
          handleSelect: (selectedOption) => {
            formikProps.setFieldValue('subscriptionType', selectedOption.value);
            setSelectedSubscriptionType(selectedOption.value);
          },
        }}
      />
    </>
  );
};

export default SubscriptionTypeDropdown;
