import { useEffect, useState } from 'react';

import useFetchOrganizationUsersQuery from 'legacy/features/organizations/api/queries/useFetchOrganizationUsersQuery';
import UserListExportButton from 'legacy/features/organizations/components/buttons/UserListExportButton';
import OrganizationUserListingCard from 'legacy/features/organizations/components/mobile/OrganizationUserListingCard';
import useOrganizationUserTableColumns from 'legacy/features/organizations/hooks/useOrganizationUserTableColumns';
import { SYSTEM_ADMIN, userRoleOptions } from 'legacy/shared/constants/users';
import ListingCount from 'legacy/shared/controls/ListingCount/ListingCount';
import WcpCheckbox from 'legacy/shared/controls/WcpCheckbox/v1/WcpCheckbox';
import LoadingWrapper from 'legacy/shared/controls/WcpLoadingWrapper/v1/LoadingWrapper';
import MediaWrapper from 'legacy/shared/controls/WcpMediaWrapper/v1/MediaWrapper';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';
import { Checkbox } from 'legacy/shared/styles/custom/Checkbox';
import CheckboxInner from 'legacy/shared/styles/custom/CheckboxInner';
import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import {
  ExportDiv,
  ExportWrapper,
  TableCheckboxFilterSpan,
  TableCheckboxFilterWrapper,
} from 'legacy/shared/styles/custom/PageList';
import { styles } from 'legacy/shared/styles/inline/inlineStyles';

const OrganizationUserListingTab = ({ organization }) => {
  const fetchOrganizationUsersQuery = useFetchOrganizationUsersQuery({
    organizationId: organization.organizationId,
  }); // TODO: check if this only fetches users for the organization
  const [filteredOrganizationUsers, setFilteredOrganizationUsers] = useState([]);
  const [disabledUsersFilterIsChecked, setDisabledUsersFilterIsChecked] = useState(true);

  useEffect(() => {
    if (!fetchOrganizationUsersQuery.data) return;

    const usersExceptSystemAdmin = fetchOrganizationUsersQuery.data.filter(
      (user) => user.userRole !== userRoleOptions[SYSTEM_ADMIN].value,
    );

    setFilteredOrganizationUsers(
      disabledUsersFilterIsChecked
        ? usersExceptSystemAdmin.filter((u) => u.isActive === true)
        : usersExceptSystemAdmin,
    );
  }, [disabledUsersFilterIsChecked, fetchOrganizationUsersQuery.data]);

  const organizationUserTableColumns = useOrganizationUserTableColumns({
    organization,
  });

  return (
    <LoadingWrapper
      isLoading={!fetchOrganizationUsersQuery.data || fetchOrganizationUsersQuery.isFetching}
    >
      <MediaWrapper
        mobileComponent={
          <div>
            <ListingCount listingCount={filteredOrganizationUsers.length} dataLabel={'User'} />
            <TableCheckboxFilterWrapper>
              <Checkbox singleCheck>
                <CheckboxInner
                  isChecked={disabledUsersFilterIsChecked}
                  onClick={() => setDisabledUsersFilterIsChecked(!disabledUsersFilterIsChecked)}
                />
              </Checkbox>
              <TableCheckboxFilterSpan>Hide disabled users</TableCheckboxFilterSpan>
            </TableCheckboxFilterWrapper>
            <Cards>
              {filteredOrganizationUsers.map((user) => (
                <OrganizationUserListingCard user={user} organization={organization} />
              ))}
            </Cards>
          </div>
        }
        nonMobileComponent={
          <div style={styles.organizations.vehiclesBody}>
            <ExportWrapper>
              <ExportDiv>
                <UserListExportButton
                  users={filteredOrganizationUsers}
                  organizationName={organization.organizationName}
                />
              </ExportDiv>
            </ExportWrapper>

            <TableCheckboxFilterWrapper orgTable>
              <WcpCheckbox
                styleProp="orgTable"
                domId="hideDisabledUsersCheckbox"
                label={'Hide disabled users'}
                checked={disabledUsersFilterIsChecked}
                handleChange={() => setDisabledUsersFilterIsChecked(!disabledUsersFilterIsChecked)}
              />
            </TableCheckboxFilterWrapper>
            <WcpTable
              columns={organizationUserTableColumns}
              tableSourceData={filteredOrganizationUsers}
              defaultSortProperty="username"
              emptyText="No users are currently associated with this organization"
              showListingCountHeader={true}
              listingCountItemName="User"
            />
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default OrganizationUserListingTab;
