import { useState, useEffect } from 'react';
import { TAB_NAMES } from 'legacy/features/integrations/constants/constants';
import WcpTable from 'legacy/shared/controls/WcpTable/v2/WcpTable';

import { partnershipsTableColumns } from 'legacy/features/integrations/ui/components/tables/PartnershipsTableColumns';
import { universalApiTableColumns } from 'legacy/features/integrations/ui/components/tables/UniversalApiTableColumns';
import { UniversalApiWrapper } from 'legacy/shared/styles/custom/Integrations';
import MediaWrapper from 'legacy/shared/controls/WcpMediaWrapper/v1/MediaWrapper';
import PartnershipsSummaryCardContainer from 'legacy/features/integrations/ui/components/mobile/PartnershipsSummaryCardContainer';
import UniversalApiSummaryCardContainer from 'legacy/features/integrations/ui/components/mobile/UniversalApiSummaryCardContainer';

const IntegrationsSummary = ({ selectedTab }) => {
  const partnershipIntegrationsDummyData = [
    { name: 'Miovision', status: 'Enabled', enabled: true, detail: 'N/A' },
    { name: 'HAAS', status: 'Enabled', enabled: true, detail: 'Integration Pending' },
    {
      name: 'Applied Information',
      status: 'Enabled',
      enabled: true,
      detail: 'Business ID: 12332145',
    },
    { name: 'Thrugreen', status: 'Enabled', enabled: true, detail: 'N/A' },
    { name: 'New Company', status: 'Disabled', enabled: false, detail: 'N/A' },
  ];

  const universalApiDummyData = [
    {
      keyName: 'New Key',
      keyType: 'Fleet Management',
      permissions: 'Read',
      createdBy: 'mrockwell',
      timestamp: '2025-11-11T15:39:58.620Z',
      formattedTime: '11/11/2025',
    },
    {
      keyName: 'New GPS Key',
      keyType: 'GPS',
      permissions: 'Read and Write',
      createdBy: 'mrockwell',
      timestamp: '2025-11-11T15:39:58.620Z',
      formattedTime: '11/11/2025',
    },
    {
      keyName: 'Matts Read/Write Key',
      keyType: 'GPS',
      permissions: 'Read and Write',
      createdBy: 'mrockwell',
      timestamp: '2025-09-22T15:39:58.620Z',
      formattedTime: '09/22/2025',
    },
  ];

  return (
    <>
      {selectedTab === TAB_NAMES.PARTNERSHIPS ? (
        <MediaWrapper
          mobileComponent={
            <PartnershipsSummaryCardContainer partnerships={partnershipIntegrationsDummyData} />
          }
          nonMobileComponent={
            <WcpTable
              id="partnershipsSummaryTable"
              columns={partnershipsTableColumns}
              tableSourceData={partnershipIntegrationsDummyData}
              showSearch={false}
            />
          }
        />
      ) : (
        <UniversalApiWrapper>
          <MediaWrapper
            mobileComponent={
              <UniversalApiSummaryCardContainer universalApiKeys={universalApiDummyData} />
            }
            nonMobileComponent={
              <WcpTable
                id="universalApiSummaryTable"
                columns={universalApiTableColumns}
                tableSourceData={universalApiDummyData}
              />
            }
          />
        </UniversalApiWrapper>
      )}
    </>
  );
};

export default IntegrationsSummary;
