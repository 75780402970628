import useWcpModalContracts from 'legacy/shared/controls/WcpModal/v2/hooks/useWcpModalContracts';
import WcpModalProvider from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';

const OrganizationDetailModalProvider = ({ children }) => {
  const modalContracts = useWcpModalContracts();

  return <WcpModalProvider modalContracts={modalContracts}>{children}</WcpModalProvider>;
};

export default OrganizationDetailModalProvider;
