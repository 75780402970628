import useOrganizationActivationMutation from 'legacy/features/organizations/api/mutations/useOrganizationActivationMutation';
import { DEACTIVATE_ORGANIZATION_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/modalKeys';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import React from 'react';

const OrganizationActivationToggleMenuItem = ({ organization }) => {
  const deactivateOrganizationConfirmationModalContext = useWcpModalContext(
    DEACTIVATE_ORGANIZATION_MODAL_KEY,
  );

  const openConfirmationModal = ({ organization, activate }) => {
    deactivateOrganizationConfirmationModalContext.modalActions.openModal({
      organization,
      activate,
    });
  };

  const organizationActivationMutation = useOrganizationActivationMutation();

  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn
        onClick={async () => {
          if (organization.isActive) {
            openConfirmationModal({ organization, activate: false });
          } else {
            await organizationActivationMutation.mutateAsync({
              organization,
              activate: true,
            });
          }
        }}
      >
        {organization.isActive ? 'Deactivate' : 'Activate'}
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default OrganizationActivationToggleMenuItem;
