import { createPortal } from 'react-dom';
import { WcpSvgIconFactory } from 'legacy/shared/controls/WcpIcon/v2/WcpSvgIcon';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import {
  StyledDivActionsContainer,
  StyledDivModalCloseButton,
  StyledDivModalContainer,
  StyledDivModalContent,
  StyledDivModalIcon,
  StyledDivModalPortalContent,
  StyledDivModalPortalOverlay,
  StyledH3ModalTitle,
  StyledModalHeader,
} from 'legacy/shared/controls/WcpModal/v2/styles/WcpModalStyles';

const WcpModal = ({
  children,
  modalKey,
  modalTitle,
  modalStyleOptions,
  modalContentStyleOptions,
  modalControlsComponent: ModalControls,
  modalIcon,
}) => {
  const modalContext = useWcpModalContext(modalKey);

  const { modalState, modalActions } = modalContext;

  const { isOpen } = modalState;
  const { closeModal } = modalActions;

  const portalRoot = document.getElementById('root');

  return (
    isOpen &&
    createPortal(
      <StyledDivModalPortalContent>
        <StyledDivModalContainer {...modalStyleOptions}>
          <StyledModalHeader>
            {/* only render the icon if it is set */}
            {modalIcon && <StyledDivModalIcon>{modalIcon}</StyledDivModalIcon>}
            <StyledH3ModalTitle>{modalTitle}</StyledH3ModalTitle>
            <StyledDivModalCloseButton onClick={() => closeModal()} title="Close">
              {WcpSvgIconFactory.CLOSE}
            </StyledDivModalCloseButton>
          </StyledModalHeader>
          <StyledDivModalContent {...modalContentStyleOptions}>
            {children(modalContext)}
          </StyledDivModalContent>
          {ModalControls && (
            <StyledDivActionsContainer>
              <ModalControls
                modalState={modalState}
                modalActions={modalActions}
                {...ModalControls.props}
              />
            </StyledDivActionsContainer>
          )}
        </StyledDivModalContainer>
        <StyledDivModalPortalOverlay onClick={() => closeModal()} />
      </StyledDivModalPortalContent>,
      portalRoot,
    )
  );
};

export default WcpModal;
