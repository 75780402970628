import { useRef, useState } from 'react';

import FilteredDropdown from 'legacy/shared/controls/WcpFilteredDropdown/v4/FilteredDropdown';
import useMakeFormikField from 'legacy/shared/controls/WcpForm/v1/hooks/useMakeFormikField';
import DropdownOptions from 'legacy/shared/utilities/classes/dropdown/DropdownOptions';
import { getFormattedDuid } from 'legacy/shared/utilities/helpers/formatting/getFormattedDuid';
import ResetVsgRegistrationModal from 'legacy/features/vehicles/components/modals/ResetVsgRegistrationModal';
import ConfirmNullVsgIdModal from 'legacy/features/vehicles/components/modals/ConfirmNullVsgIdModal';
import { VehicleEditQRScannerButton } from 'legacy/shared/styles/custom/Button';
import {
  VsgIdSelectionInnerWrapperDiv,
  VsgIdSelectionWrapperDiv,
} from 'legacy/shared/styles/custom/VehicleEdit';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import QRCodeReader from 'legacy/shared/controls/QrCodeReader/QRCodeReader';
import { FieldError } from 'legacy/shared/styles/custom/Form';

const VehicleDeviceIdField = ({
  formikProps: { values, errors, setFieldValue },
  resetPreferencesModalActive,
  handleConfirmVsgResetPrefencesAccepted,
  handleDeviceIdDirty,
  handleChange,
  handleConfirmVerifyVsgIdAccepted,
  nullVsgModalActive,
  unassociatedVsgs,
  duid,
  setDuid,
}) => {
  const vsgDropdownOptions = new DropdownOptions(
    unassociatedVsgs?.map((vsg) => ({
      label: getFormattedDuid(vsg.vsgDuid),
      value: vsg.vsgDuid,
    })) || [],
  );

  const deviceIdRef = useRef();
  const [isDeviceScannerOpen, setDeviceScannerOpen] = useState(false);

  const toggleDeviceScanner = () => setDeviceScannerOpen((prevState) => !prevState);

  const handleQRCodeScan = () => (scannedResult) => {
    scannedResult = scannedResult.replace(/(\r\n|\n|\r)/gm, '');
    setDuid(scannedResult);
    toggleDeviceScanner();
    handleDeviceIdDirty(true);
    setFieldValue('duid', scannedResult);
  };

  useMakeFormikField({
    fieldName: 'duid',
    fieldValue: duid,
  });
  return (
    <>
      {resetPreferencesModalActive ? (
        <ResetVsgRegistrationModal
          handleUserResponse={(isAccepted) => {
            handleConfirmVsgResetPrefencesAccepted(isAccepted);
          }}
        />
      ) : null}
      {nullVsgModalActive ? (
        <ConfirmNullVsgIdModal
          handleUserResponse={(isAccepted) => {
            handleConfirmVerifyVsgIdAccepted(isAccepted);
          }}
        />
      ) : null}
      <VsgIdSelectionWrapperDiv>
        <VsgIdSelectionInnerWrapperDiv noVsgSelected={!duid}>
          <FilteredDropdown
            domId={'vsgRegistrationIdDropdown'}
            fieldLabel={'VSG Registration ID'}
            placeholderLabel="Select a VSG ID..."
            styleOptions={{
              showClearSelectionButton: true,
              narrowWidth: false,
              fullWidth: true,
            }}
            setup={{
              dropdownOptions: vsgDropdownOptions,
              preselectedOption: duid
                ? {
                    label: getFormattedDuid(duid), // TODO, some VSG ids do not get formatted correctly here
                    value: duid,
                  }
                : null,
            }}
            eventHandlers={{
              handleSelect: (selectedOption) => {
                handleDeviceIdDirty(selectedOption.value !== duid);
                setDuid(selectedOption.value);
              },
            }}
          />
        </VsgIdSelectionInnerWrapperDiv>
        {!duid && (
          <div>
            <VehicleEditQRScannerButton type="button" onClick={toggleDeviceScanner}>
              <IconSvgComponent
                svgFileName={'qr-scanner'}
                title="Scan QR code"
                alt="Scan QR code"
              />
            </VehicleEditQRScannerButton>
          </div>
        )}
      </VsgIdSelectionWrapperDiv>
      <QRCodeReader
        isOpen={isDeviceScannerOpen}
        handleScan={handleQRCodeScan(setFieldValue, 'duid', deviceIdRef, toggleDeviceScanner)}
      />
    </>
  );
};

export default VehicleDeviceIdField;
