import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import ViewMaintenanceLogsMenuItem from 'legacy/features/maintenance/components/MaintenanceSummary/menus/ViewMaintenanceLogsMenuItem';
import VehicleListExportButton from 'legacy/features/vehicles/components/buttons/VehicleListExportButton';
import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import ProtectedElement from 'legacy/core/components/authorization/ProtectedElement';
import VehicleList from 'legacy/features/vehicles/components/VehicleList';
import {
  allAdminUserRoles,
  FIELD_SOLUTION_ENGINEER,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  permissionData,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'legacy/shared/constants/users';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';
import { DropdownLink, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import { PageListActionButtonContainer, PageListTitle } from 'legacy/shared/styles/custom/PageList';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';
import SimpleMenuItem from 'legacy/shared/controls/SimpleMenuItem/SimpleMenuItem';
import ToggleLockVehicleMenuItem from 'legacy/features/vehicles/components/menus/ToggleLockVehicleMenuItem';
import ViewLogsMenuItem from 'legacy/features/vehicles/components/menus/ViewLogsMenuItem';
import ViewOnMapMenuItem from 'legacy/features/vehicles/components/menus/ViewOnMapMenuItem';
import LoadingOverlay from 'legacy/shared/controls/WcpSpinner/LoadingOverlay';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import ToggleLockWidget from 'legacy/shared/controls/ToggleLock/ToggleLockWidget';
import { getSubscriptionDevices } from 'legacy/features/billing/api/get/getSubscriptionDevices';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';

const VehicleListingPage = () => {
  const isHomeOrg = useSelector(
    (state) => state.user.user.organizationId === state.user.filteredOrganizationId,
  );

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const [vehiclesQuery] = useFetchVehiclesQuery({
    apiFlags: {
      addons: true,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  let vehicles = vehiclesQuery?.data;

  const subscriptionDevicesQuery = useQuery({
    queryKey: ['getSubscriptionDevicesApi'],
    queryFn: () => getSubscriptionDevices(filteredOrganizationId),
  });

  let subscriptionVsgs =
    subscriptionDevicesQuery.data && Array.isArray(subscriptionDevicesQuery.data)
      ? subscriptionDevicesQuery.data
      : null;
  let mappedVsgs = subscriptionVsgs?.map((vsg) => {
    return Object.entries(vsg).reduce((acc, [key, value]) => {
      let newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
      acc[newKey] = value;
      return acc;
    }, {});
  });

  return vehiclesQuery.isFetching && !vehiclesQuery.data ? (
    <LoadingOverlay />
  ) : (
    <>
      <VehicleList
        vehicles={vehicles}
        mappedVsgs={mappedVsgs}
        vehiclesFetching={vehiclesQuery.isFetching}
        leftHeaderComponent={<PageListTitle>Vehicles</PageListTitle>}
        rightHeaderComponent={({ commands }) => (
          <PageListActionButtonContainer>
            {isHomeOrg ? <ToggleLockWidget vehiclesOrVehicle={vehicles} /> : null}
            <VehicleListExportButton vehicles={vehicles} />
            <Link
              to={{
                pathname: `/createvehicle`,
              }}
              style={{ textDecoration: 'none' }}
            >
              <ActionButtonWithIcon create>
                <IconSvgComponent svgFileName="plus-simple" alt="Create" />

                <ActionButtonText>Create Vehicle </ActionButtonText>
              </ActionButtonWithIcon>
            </Link>
          </PageListActionButtonContainer>
        )}
        actionMenuComponent={(vehicle, commands) => {
          return (
            <ActionMenu>
              <SimpleDropdownItem>
                <DropdownLink to={`/vehicle/${encodeURIComponent(vehicle.vehicleId)}`}>
                  Edit Vehicle
                </DropdownLink>
              </SimpleDropdownItem>
              {isHomeOrg ? (
                <ToggleLockVehicleMenuItem
                  vehicle={vehicle}
                  onClick={() => commands.showToggleLockModal(vehicle)}
                />
              ) : null}

              <PermissionProtectedElement requiredPermissions={[permissionData.viewmap]}>
                <SimpleDropdownItem>
                  <ViewOnMapMenuItem vehicle={vehicle} />
                </SimpleDropdownItem>
              </PermissionProtectedElement>

              <PermissionProtectedElement
                requiredPermissions={[permissionData.editfleet, permissionData.viewanalytics]}
              >
                <SimpleDropdownItem>
                  <ViewLogsMenuItem vehicle={vehicle} />
                </SimpleDropdownItem>
              </PermissionProtectedElement>
              <SimpleDropdownItem>
                <ViewMaintenanceLogsMenuItem vehicleId={vehicle.vehicleId} />
              </SimpleDropdownItem>
              {/* Regardless of customizable permissions, roles outside of the following roles should never be able to reset a vehicle */}
              <ProtectedElement
                allowedRoles={[
                  userRoles[FIELD_SOLUTION_ENGINEER].value,
                  userRoles[SYSTEM_ADMIN].value,
                  userRoles[FLEET_ANALYST].value,
                  userRoles[ORGANIZATION_ADMIN].value,
                ]}
              >
                <SimpleMenuItem title={'Reset'} onClick={() => commands.showResetModal(vehicle)} />
              </ProtectedElement>

              <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
                <SimpleMenuItem
                  title={'Delete'}
                  onClick={() => commands.showDeleteModal(vehicle)}
                />
              </PermissionProtectedElement>
            </ActionMenu>
          );
        }}
      />
    </>
  );
};

export default VehicleListingPage;
