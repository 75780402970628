import { useEffect, useState } from 'react';

import WcpFormCancelButton from 'legacy/shared/controls/WcpForm/v2/components/buttons/WcpFormCancelButton';
import WcpFormResetButton from 'legacy/shared/controls/WcpForm/v2/components/buttons/WcpFormResetButton';
import WcpFormSubmitButton from 'legacy/shared/controls/WcpForm/v2/components/buttons/WcpFormSubmitButton';
import { WcpFormContext } from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import {
  StyledDivFormActions,
  StyledDivFormBody,
  StyledDivFormFooter,
  StyledDivFormHeader,
  StyledDivFormIcon,
  StyledForm,
  StyledH4FormTitle,
  StyledSpanFormLegend,
  StyledSpanFormRequiredAsterisk,
  StyledSpanFormSummaryValidationMessage,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFormStyles';
import { defaultValidationSettings } from 'legacy/shared/controls/WcpForm/v2/constants/defaultParams';
import {
  validationRestrictionEnum,
  whatToValidateEnum,
  whenToValidateEnum,
} from 'legacy/shared/controls/WcpForm/v2/constants/enums';
import usePostMountEffect from 'legacy/shared/utilities/hooks/usePostMountEffect';
import { useRef } from 'react';

const WcpForm = ({
  children,
  formData = {},

  // form view settings
  formTitle,
  iconComponent,
  showFormHeader = true,
  showLegend = true,
  showResetButton = false,
  showCancelButton = true,
  submitButtonText,

  // validation settings
  validationSchema,
  validationSettings = defaultValidationSettings,

  // event handlers
  handleSubmit,
  handleCancel,

  handleOnTouched: handleOnFormTouched,
  handleOnDirty: handleOnFormDirty,
  handleOnChange: handleOnFormValuesChanged,
}) => {
  // form validation settings destructured
  const {
    showFormValidationMessage,
    formValidationMessage,
    whenToValidate,
    whatToValidate,
    validationRestrictions,
  } = { ...defaultValidationSettings, ...validationSettings };

  const formHasBeenModified = useRef(false);

  const [formValues, setFormValues] = useState(formData);
  const [initialFormValues, setInitialFormValues] = useState(formData);

  const [touchedFields, setTouchedFields] = useState([]);
  const [dirtyFields, setDirtyFields] = useState([]);

  const [focusedField, setFocusedField] = useState(null);

  const [validationErrors, setValidationErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValidationMessageVisible, setFormValidationMessageVisible] = useState(false);

  const requiredFields = Object.keys(validationSchema.fields).filter(
    (dk) => validationSchema.fields[dk].spec.optional === false,
  );

  useEffect(() => {
    // setting initial form values
    setInitialFormValues(formData);

    if (whenToValidate.includes(whenToValidateEnum.ON_LOAD)) {
      validate();
    }
  }, []);

  const validate = async () => {
    console.log(
      `validate() called with the following settings:
        When: ${whenToValidate}
        What: ${whatToValidate}
        Restrictions: ${validationRestrictions}`,
    );

    console.log(
      `Form State:
        Touched: ${touchedFields.length > 0 ? touchedFields.join(', ') : 'none'}
        Dirty: ${dirtyFields.length > 0 ? dirtyFields.join(', ') : 'none'}
        Focused: ${focusedField || 'none'}`,
    );
    // bypass validation if one of the validation restrictions is not met
    if (
      (validationRestrictions.includes(validationRestrictionEnum.FORM_MUST_BE_DIRTY) &&
        dirtyFields.length === 0) ||
      (validationRestrictions.includes(validationRestrictionEnum.FORM_MUST_BE_TOUCHED) &&
        touchedFields.length === 0) ||
      validationRestrictions.includes(
        validationRestrictionEnum.FORM_MUST_BE_FOCUSED && !focusedField,
      ) ||
      (validationRestrictionEnum.FORM_MUST_HAVE_BEEN_DIRTY && !formHasBeenModified.current)
    ) {
      console.log('Validation bypassed due to restriction');
      return;
    }

    // validate based on whatToValidate settings
    if (whatToValidate.includes(whatToValidateEnum.TOUCHED_FIELDS)) {
      validateFields([touchedFields]);
    }
    if (whatToValidate.includes(whatToValidateEnum.DIRTY_FIELDS)) {
      validateFields([dirtyFields]);
    }
    if (whatToValidate.includes(whatToValidateEnum.FOCUSED_FIELDS)) {
      validateField({ dataKey: focusedField });
    }
    if (whatToValidate.includes(whatToValidateEnum.ALL_FIELDS)) {
      validateForm();
    }
  };

  const validateField = async ({ dataKey }) => {
    try {
      await validationSchema.validateAt(dataKey, formValues);
      setValidationErrors(validationErrors.filter((error) => error.path !== dataKey));
      return true;
    } catch (e) {
      setValidationErrors([e]);
      return false;
    }
  };

  const validateFields = async ([dataKeys]) => {
    const validationErrors = await Promise.all(
      dataKeys
        .map(async (dk) => {
          try {
            await validationSchema.validateAt(dk, formValues);
            return null;
          } catch (e) {
            return e;
          }
        })
        .filter((error) => error !== null),
    );

    setValidationErrors(validationErrors.filter((error) => error !== null));
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });
      setValidationErrors([]);
      return true;
    } catch (e) {
      setValidationErrors(e.inner);
      return false;
    }
  };

  useEffect(() => {
    if (validationErrors) 'WcpForm validationErrors changed', validationErrors;
  }, [validationErrors]);

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    // try catch is here to catch re-thrown mutation errors so that toast message can be displayed and prevent render errors
    try {
      let success = await validateForm();

      if (success) {
        setFormValidationMessageVisible(false);
        setIsSubmitting(true);
        await handleSubmit({ formValues });
        setIsSubmitting(false);
      } else {
        setFormValidationMessageVisible(true);
      }
    } catch (e) {
      //make sure to dispatch toast in onError of whatever mutation that calls this
      setIsSubmitting(false);
    }
  };

  // when focused field changes
  usePostMountEffect(() => {
    if (!!focusedField) {
      console.log('field focused');
      whenToValidate.includes(whenToValidateEnum.ON_FOCUS) && validate();
    } else {
      console.log('field blurred');
      whenToValidate.includes(whenToValidateEnum.ON_BLUR) && validate();
    }
  }, [focusedField]);

  // when touched fields change
  usePostMountEffect(() => {
    console.log('field touched');
    whenToValidate.includes(whenToValidateEnum.ON_TOUCHED) && validate();

    handleOnFormTouched && handleOnFormTouched(touchedFields);
  }, [touchedFields]);

  // when dirty fields change
  usePostMountEffect(() => {
    console.log('field dirty');
    whenToValidate.includes(whenToValidateEnum.ON_DIRTY) && validate();

    handleOnFormDirty && handleOnFormDirty(dirtyFields);
  }, [dirtyFields]);

  // when form values change
  usePostMountEffect(() => {
    console.log('field changed');
    whenToValidate.includes(whenToValidateEnum.ON_CHANGE) && validate();

    handleOnFormValuesChanged && handleOnFormValuesChanged(formValues);
  }, [formValues]);

  // handle field focus
  const handleOnFocus = ({ dataKey }) => {
    setFocusedField(dataKey);

    if (touchedFields.indexOf(dataKey) === -1) {
      setTouchedFields([...touchedFields, dataKey]);
    }

    whenToValidate.includes(whenToValidateEnum.ON_FOCUS) && validate();
  };

  const handleOnBlur = () => {
    setFocusedField(null);
  };

  const handleOnChange = ({ dataKey, input }) => {
    setFormValues({ ...formValues, [dataKey]: input });

    // set form as modified as soon as a field changes (dirty or not)
    formHasBeenModified.current = true;

    // set field dirty if it has changed from initial value
    if (initialFormValues[dataKey] !== input) {
      // add field to dirty fields if it's not already there
      if (dirtyFields.indexOf(dataKey) === -1) {
        setDirtyFields([...dirtyFields, dataKey]);
      }
    } else {
      // remove field from dirty fields if it's there and has been reset to initial value
      setDirtyFields(dirtyFields.filter((field) => field !== dataKey));
    }

    handleOnFormValuesChanged && handleOnFormValuesChanged({ dataKey, input });
  };

  const formContextValue = {
    initialFormValues,
    formValues,
    setFormValues: (newValues) => setFormValues({ ...formValues, ...newValues }),

    // field states
    touchedFields,
    setTouchedFields,
    dirtyFields,
    setDirtyFields,
    focusedField,
    setFocusedField,

    // form state
    isFormTouched: touchedFields.length > 0,
    isFormDirty: dirtyFields.length > 0,

    // event handlers
    handleOnFocus,
    handleOnBlur,
    handleOnChange,

    // validation
    requiredFields,
    focusedField,
    validationErrors,
  };

  return (
    <WcpFormContext.Provider value={formContextValue}>
      <StyledForm>
        {showFormHeader && (
          <StyledDivFormHeader>
            <StyledDivFormIcon>{iconComponent}</StyledDivFormIcon>
            {formTitle && <StyledH4FormTitle>{formTitle}</StyledH4FormTitle>}
          </StyledDivFormHeader>
        )}
        <StyledDivFormBody>{children}</StyledDivFormBody>
        <StyledDivFormFooter>
          {showLegend && (
            <StyledSpanFormLegend>
              <StyledSpanFormRequiredAsterisk showRequiredText={true} />
            </StyledSpanFormLegend>
          )}
          {showFormValidationMessage && formValidationMessageVisible && (
            <StyledSpanFormSummaryValidationMessage>
              {formValidationMessage}
            </StyledSpanFormSummaryValidationMessage>
          )}
          <StyledDivFormActions>
            {showResetButton && <WcpFormResetButton />}
            {showCancelButton && <WcpFormCancelButton handleClick={handleCancel} />}
            <WcpFormSubmitButton
              isSubmitting={isSubmitting}
              disabled={false}
              buttonText={submitButtonText}
              notLoadingStyleProp={'mediumAlt'}
              handleClick={handleSubmitClick}
            />
          </StyledDivFormActions>
        </StyledDivFormFooter>
      </StyledForm>
    </WcpFormContext.Provider>
  );
};

export default WcpForm;
