import {
  CREATE_ORGANIZATION_MODAL_KEY,
  EDIT_ORGANIZATION_MODAL_KEY,
  DEACTIVATE_ORGANIZATION_MODAL_KEY,
} from 'legacy/features/organizations/providers/modal/constants/modalKeys';
import useWcpModalContracts from 'legacy/shared/controls/WcpModal/v2/hooks/useWcpModalContracts';
import WcpModalProvider from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';

const OrganizationListingModalProvider = ({ children }) => {
  const modalContracts = useWcpModalContracts(
    CREATE_ORGANIZATION_MODAL_KEY,
    EDIT_ORGANIZATION_MODAL_KEY,
    DEACTIVATE_ORGANIZATION_MODAL_KEY,
  );

  return <WcpModalProvider modalContracts={modalContracts}>{children}</WcpModalProvider>;
};

export default OrganizationListingModalProvider;
