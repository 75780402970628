import { EDIT_ORGANIZATION_MODAL_KEY } from 'legacy/features/organizations/providers/modal/constants/modalKeys';
import { useWcpModalContext } from 'legacy/shared/controls/WcpModal/v2/WcpModalProvider';
import { ActionButtonWithIcon } from 'legacy/shared/styles/custom/Button';

const EditOrganizationButton = ({ organization }) => {
  const editOrganizationModalContext = useWcpModalContext(EDIT_ORGANIZATION_MODAL_KEY);

  return (
    <ActionButtonWithIcon
      orgDetail
      orgEdit
      onClick={() => editOrganizationModalContext.modalActions.openModal({ organization })}
    >
      Edit Organization
    </ActionButtonWithIcon>
  );
};

export default EditOrganizationButton;
