import React, { useState, useEffect } from 'react';
import SortableTableWithoutPagination from 'legacy/shared/controls/WcpTable/v1/SortableTableWithoutPagination';
import { Checkbox } from 'legacy/shared/styles/custom/Checkbox';
import colors from 'legacy/shared/constants/colors';

// assets
import checkDisabledPNG from 'assets/images/check-disabled@2x.png';
import checkCheckedPNG from 'assets/images/check@2x.png';

import { fetchAPICall, getAuthToken } from 'legacy/core/api/API';
import { baseApiUrlFirmwareRepo } from 'legacy/shared/constants/api';

const ChannelSelectTable = ({ productId, channels, onSelect, onDeselect }) => {
  const [selected, setSelected] = useState([]);
  const [channelsWithVersions, setChannelsWithVersions] = useState([]);

  const getLatestVersion = async (channel) => {
    let response = await fetchAPICall({
      endpoint: 'cloudfrontAPI',
      baseURI: baseApiUrlFirmwareRepo,
      resourcePath: `/firmware/${productId}/channel/${channel}`,
      method: 'GET',
      headers: {
        Authorization: getAuthToken(),
      },
    });

    return response.version;
  };

  const getChannelsWithVersions = async () => {
    let channelsWithVersions = await Promise.all(
      channels.map(async (c) => ({
        label: c.value,
        value: c.value,
        selected: c.selected,
        version: await getLatestVersion(c.value),
      })),
    );

    setChannelsWithVersions(channelsWithVersions);
  };

  useEffect(() => {
    getChannelsWithVersions();
  }, []);

  useEffect(() => {
    setSelected(channelsWithVersions.filter((c) => c.selected).map((channel) => channel.value));
  }, [channelsWithVersions]);

  const handleCheckbox = (row, index) => {
    return (e) => {
      const indexOfItem = selected.indexOf(row.value);
      let nextSelected = selected.slice();

      if (row.value === 'PRODUCTION') {
        return;
      }

      if (indexOfItem > -1) {
        nextSelected.splice(indexOfItem, 1);
        onDeselect(row.value);
      } else {
        nextSelected = [...nextSelected, row.value];
        onSelect(row.value);
      }
      setSelected(nextSelected);
    };
  };

  return (
    <div style={styles.tableWrapper}>
      <SortableTableWithoutPagination
        data={channelsWithVersions}
        rowKey={(record) => record.value}
        columns={[
          {
            title: 'Channel',
            dataIndex: 'label',
            key: 'channel',
            render: (value, row, index) => {
              const s = selected.indexOf(row.value) > -1;
              const isProduction = value === 'PRODUCTION';
              return (
                <div style={styles.channelNameColumn}>
                  <Checkbox>
                    <div
                      style={
                        !s
                          ? styles.checkboxHolderImage
                          : isProduction
                            ? styles.checkboxHolderImageSelectedDisabled
                            : styles.checkboxHolderImageSelected
                      }
                      onClick={handleCheckbox(row, index)}
                    />
                    <span
                      style={{
                        ...styles.channelName,
                        ...(isProduction ? styles.productionChannelName : {}),
                      }}
                    >
                      {value} (v {row.version})
                    </span>
                  </Checkbox>
                </div>
              );
            },
            onHeaderCell: () => ({ style: styles.channelNameColumn }),
            onCell: () => ({ style: styles.channelNameCell }),
          },
        ]}
      />
    </div>
  );
};

export default ChannelSelectTable;

const styles = {
  tableWrapper: {
    marginLeft: -30,
    marginRight: -30,
  },
  channelNameColumn: {
    paddingLeft: 47,
  },
  channelNameCell: {
    padding: '16px 8px 16px 0',
  },
  channelName: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 500,
    fontSize: 14,
    color: colors.midnight,
  },
  productionChannelName: {
    color: colors.concrete,
  },
  checkboxHolderImage: {
    position: 'relative',
    display: 'inline-block',
    top: 'calc(50% + 2px)',
    width: 14,
    height: 14,
    marginRight: 20,
    borderRadius: 2,
    border: '1px solid rgba(138, 144, 156, 0.6)',
  },
  checkboxHolderImageSelected: {
    position: 'relative',
    display: 'inline-block',
    top: 'calc(50% + 2px)',
    borderRadius: 2,
    border: '1px solid rgba(138, 144, 156, 0.6)',
    width: 14,
    height: 14,
    marginRight: 20,
    border: 'none',
    backgroundImage: `url(${checkCheckedPNG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  checkboxHolderImageSelectedDisabled: {
    position: 'relative',
    display: 'inline-block',
    top: 'calc(50% + 2px)',
    borderRadius: 2,
    border: '1px solid rgba(138, 144, 156, 0.6)',
    width: 14,
    height: 14,
    marginRight: 20,
    border: 'none',
    backgroundImage: `url(${checkDisabledPNG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};
