import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import React from 'react';
import { BlueButton } from 'legacy/shared/styles/custom/Button';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const AddAdditionalNotificationButton = ({ domId }) => {
  const {
    actions: { addAlertNotificationAction },
    state: {
      alert: { alertId },
    },
  } = useAlertFormsProviderContext();

  return (
    <BlueButton
      id={domId}
      type="button"
      addNotification
      onClick={() => addAlertNotificationAction({ alertId })}
    >
      <IconSvgComponent svgFileName="plus-white" alt="Add Additional Notification" />
      Add Additional Notification
    </BlueButton>
  );
};

export default AddAdditionalNotificationButton;
