import styled from 'styled-components';

import colors, { hexToRGBA } from 'legacy/shared/constants/colors';
import { convertToCamelCase } from 'legacy/shared/utilities/helpers/formatting/convertToCamelCase';

export const GenericButton = styled.button.attrs(({ id, type, children }) => ({
  type: type || 'button',
  id: `${id || `${convertToCamelCase(children)}${`-button`}`}`,
}))`
  display: inline-block;
  padding: 6px 15px;
  text-decoration: none;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.59px;
  color: ${colors.cotton};
  background: ${colors.blueberry};
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;
  min-width: 128px;
  margin-top: 10px;

  ${(props) =>
    props.noBackgroundColor &&
    `
    background: none;
  `};

  ${(props) =>
    props.center &&
    `
    display: block;
    margin: 0px auto 30px auto;
    text-align: center;
		width: 180px;
  `};

  ${(props) =>
    props.default &&
    `
    min-width: 128px;
    border-radius: 21px;
    font-size: 12px;
    font-weight: 800;
    color: ${colors.haze};
    border: 2px solid ${hexToRGBA({ color: colors.haze, alpha: 0.3 })};
    &:hover {
      background: ${colors.haze};
      color: ${colors.cotton};
    }
  `};
`;
