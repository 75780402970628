import { apiCall, getAuthToken } from 'legacy/core/api/API';
import { userPasswordUpdatePath, userPath } from 'legacy/shared/constants/api';

export const getUserAPI = async ({ username }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${userPath}/${username}`,
    method: 'get',
  });
};

export const getUserActivityLogs = async ({ username, organizationId, startDate, endDate }) => {
  const queryStringParameters = {
    username: `${username}`,
    organization_id: `${organizationId}`,
    start_date: `${startDate}`,
    end_date: `${endDate}`,
  };
  return await apiCall({
    path: `/audit`,
    method: 'get',
    queryStringParameters,
  });
};

export const getUsers = async ({ organizationId }) => {
  const queryStringParameters = organizationId ? { organization_id: `${organizationId}` } : '';
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userPath,
    method: 'get',
    queryStringParameters,
  });
};

export const setTwoFactorAPI = async (accessToken, twoFactorMethod) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/settwofactor',
    method: 'post',
    headers: {
      Accept: `*/*`,
      Authorization: accessToken,
    },
    body: {
      two_factor_auth_method: twoFactorMethod,
    },
  });
};

export const verifyMfaAuthCodeAPI = async (accessToken, userMfaCode) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/verifymfaauthcode',
    method: 'post',
    headers: {
      Authorization: accessToken,
    },
    body: {
      UserCode: userMfaCode,
    },
  });
};

export const updateUserPasswordAPI = async (oldPassword, newPassword) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userPasswordUpdatePath,
    method: 'post',
    body: {
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
      AccessToken: getAuthToken(),
    },
  });
};
