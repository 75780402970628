import { Cards } from 'legacy/shared/styles/custom/MobileCard';
import DevicesAndBillingSummaryCard from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/mobile/DevicesAndBillingSummaryCard';
import DevicesAndBillingActionMenu from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/menus/DevicesAndBillingActionMenu';
import useDevicesAndBillingSummaryProviderContext from 'legacy/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';

const DevicesAndBillingSummaryCardContainer = () => {
  const {
    state: { filteredDeviceSubscriptions },
  } = useDevicesAndBillingSummaryProviderContext();

  const getActionMenu = (device) => {
    return <DevicesAndBillingActionMenu vsgDuid={device.vsgDuid} />;
  };

  return (
    <div>
      <Cards>
        {filteredDeviceSubscriptions.map((ds) => (
          <DevicesAndBillingSummaryCard
            deviceSubscription={ds}
            key={ds.vsgDuid}
            actionMenuComponent={() => getActionMenu(ds)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default DevicesAndBillingSummaryCardContainer;
