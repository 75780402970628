import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import ProtectedElement from 'legacy/core/components/authorization/ProtectedElement';
import { checkForUserPermission, selectUserRole } from 'legacy/core/redux/user/selectors';
import { getAssociatedVehicles } from 'legacy/features/vehicleGroups/helpers/getAssociatedVehicles';
import { useFetchGroupByNameQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupByNameQuery';
import AddVehiclesButton from 'legacy/features/vehicleGroups/ui/buttons/AddVehiclesButton';
import VehicleList from 'legacy/prebilling/vehicles/VehicleList';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';
import ToggleLockWidget from 'legacy/shared/controls/ToggleLock/ToggleLockWidget';
import ActionMenu from 'legacy/shared/controls/WcpTable/v1/ActionMenu';
import { TruncatedText } from 'legacy/shared/styles/custom/TruncatedText';
import { DropdownLink, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';
import ToggleLockVehicleMenuItem from 'legacy/features/vehicles/components/menus/ToggleLockVehicleMenuItem';
import ViewLogsMenuItem from 'legacy/features/vehicles/components/menus/ViewLogsMenuItem';
import colors from 'legacy/shared/constants/colors';

const VehicleGroupDetailPage = ({ filteredOrganizationId, userOrganizationId, editAccess }) => {
  const { pathname } = useLocation();
  const { groupName } = useParams();

  const [vehiclesQuery, vehicles] = useFetchVehiclesQuery({
    apiFlags: {
      addons: false,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });
  const [fetchGroupsQuery, group] = useFetchGroupByNameQuery({ groupName });

  const [groupVehicles, setGroupVehicles] = useState([]);
  const isHomeOrg = userOrganizationId === filteredOrganizationId;

  useEffect(() => {
    setGroupVehicles(getAssociatedVehicles({ group, vehicles }));
  }, [vehicles, group]);

  return !group || !groupVehicles ? (
    <LoadingOverlay />
  ) : (
    <>
      {fetchGroupsQuery.isFetching || (vehiclesQuery.isFetching && <LoadingOverlay />)}
      <VehicleList
        vehicles={groupVehicles}
        leftHeaderComponent={
          <div>
            <h4 title={group.group_name}>
              <TruncatedText groupHeader>{group.group_name}</TruncatedText>
            </h4>
          </div>
        }
        rightHeaderComponent={() => (
          <>
            {isHomeOrg ? <ToggleLockWidget vehiclesOrVehicle={groupVehicles} /> : null}
            <AddVehiclesButton groupId={group.group_id} groupVehicles={groupVehicles} />
          </>
        )}
        actionMenuComponent={(vehicle, commands) =>
          editAccess ? (
            <ActionMenu>
              <SimpleDropdownItem>
                <DropdownLink
                  to={{
                    pathname: `/vehicle/${encodeURIComponent(vehicle.vehicleId)}`,
                    state: pathname,
                  }}
                >
                  Edit Vehicle
                </DropdownLink>
              </SimpleDropdownItem>

              {isHomeOrg ? (
                <ToggleLockVehicleMenuItem
                  vehicle={vehicle}
                  onClick={() => commands.showToggleLockModal(vehicle)}
                />
              ) : null}

              <PermissionProtectedElement requiredPermissions={[permissionData.viewmap]}>
                <SimpleDropdownItem>
                  <ViewOnMapMenuItem vehicle={vehicle} />
                </SimpleDropdownItem>
              </PermissionProtectedElement>

              <PermissionProtectedElement
                requiredPermissions={[permissionData.editfleet, permissionData.viewanalytics]}
              >
                <SimpleDropdownItem>
                  <ViewLogsMenuItem vehicle={vehicle} />
                </SimpleDropdownItem>
              </PermissionProtectedElement>
              {/* Regardless of customizable permissions, roles outside of the following roles should never be able to reset a vehicle */}
              <ProtectedElement
                allowedRoles={[
                  userRoles[FIELD_SOLUTION_ENGINEER].value,
                  userRoles[SYSTEM_ADMIN].value,
                  userRoles[FLEET_ANALYST].value,
                  userRoles[ORGANIZATION_ADMIN].value,
                ]}
              >
                <SimpleMenuItem title={'Reset'} onClick={() => commands.showResetModal(vehicle)} />
              </ProtectedElement>

              <SimpleDropdownItem>
                <SimpleMenuItem
                  title={'Remove'}
                  onClick={() => commands.showRemoveFromGroupModal({ vehicle, group })}
                />
              </SimpleDropdownItem>
            </ActionMenu>
          ) : null
        }
      />
    </>
  );
};

export default connect((state, ownProps) => {
  return {
    userRole: selectUserRole(state.user.user),
    filteredOrganizationId: state.user.filteredOrganizationId,
    userOrganizationId: state.user.user.organizationId,
    editAccess: checkForUserPermission(state.user, permissionData.editfleet),
  };
})(VehicleGroupDetailPage);

const styles = {
  tableHead: {
    marginBottom: 57,
    display: 'flex',
    flexWrap: 'wrap',
  },
  tableHeadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 0 100%',
  },
  groupName: {
    display: 'inline-block',
  },
  groupNameIcon: {
    marginBottom: 7,
    marginRight: 15,
  },
  tableDescription: {
    display: 'flex',
  },
  lockToggle: {
    marginRight: '20px',
  },
  editNameIcon: {
    marginLeft: 30,
    marginBottom: 7,
    cursor: 'pointer',
  },
  nameInput: {
    display: 'none',
    border: 'none',
    borderBottom: '1px solid black',
    backgroundColor: colors.fog,
    fontSize: 24,
    fontWeight: 700,
  },
};
