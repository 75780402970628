import styled from 'styled-components';

import colors from 'legacy/shared/constants/colors';
import device from 'legacy/shared/constants/sizing';
import { vehicleEditVinLookupIconStyle } from 'legacy/shared/styles/custom/Icons.js';

const hexWithOpacity = (hexColor, alpha) => `rgba(
  ${parseInt(hexColor.slice(1, 3), 16)},
  ${parseInt(hexColor.slice(3, 5), 16)},
  ${parseInt(hexColor.slice(5, 7), 16)},
  ${alpha})`;

const { haze, sky, midnight, cotton, cherry, blueberry, charcoal } = colors;

export default styled.button`
  display: inline-block;
  padding: 10px 31px;
  text-decoration: none;
  border-radius: 21px;
  border: 2px solid ${midnight};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.59px;
  color: ${midnight};
  background: ${cotton};
  cursor: pointer;
  //transition: background .3s, color .3s;
  min-width: 128px;

  &:hover {
    background: ${midnight};
    color: #fff;
  }

  ${(props) =>
    props.withRightSpacer &&
    `
		margin-right: 15px;
  `}

  ${(props) =>
    props.center &&
    `
    display: block;
    margin: 0px auto 30px auto;
    text-align: center;
		width: 180px;
  `}

	${(props) =>
    props.redLight &&
    `
		border-color: ${hexWithOpacity(midnight, 0.5)};
		color: ${hexWithOpacity(midnight, 0.5)};
	`}

	${(props) =>
    props.medium &&
    `
		padding: 10px 31px;
	`}

	${(props) =>
    props.mediumAlt &&
    `
		min-width: 180px;
		padding: 10px 31px;
		text-align: center;
	`}

${(props) =>
    props.permissionsCancel &&
    `
		min-width: 128px;
		padding: 10px 31px;
    text-align: center;
    font-size: 11px;
	`}

	${(props) =>
    props.default &&
    `
    min-width:128px;
		border-radius: 21px;
		border: 2px solid ${hexWithOpacity(haze, 0.3)};
		font-size: 12px;
		font-weight: 800;
		color: ${haze};

		&:hover {
			background: ${haze};
    }
  `}

  ${(props) =>
    props.submittingWithSpinner &&
    `
    background: ${midnight};
    color: ${midnight};
    cursor: not-allowed;
    text-align: center;
    padding: 10px 31px;
    min-width: 180px;
    font-size: 11px;
    &:hover {
			color: ${midnight};
    }
    `}

//This prop needs to be renamed at some point soon as it is used in many places, not just modals
${(props) =>
    props.submittingWithSpinnerModal &&
    `
  background: ${midnight};
    color: ${midnight};
    cursor: not-allowed;
    text-align: center;
    padding: 10px 31px;
    min-width: 180px;
    font-size: 12px;
    &:hover {
			color: ${midnight};
    }
    `}

${(props) =>
    props.otaUpdate &&
    `
      background: ${midnight};
      color: ${cotton};
      text-align: center;
      padding: 5px;
      margin-right: 0px;
      min-width: 140px;
      font-size: 11px;

    `}

${(props) =>
    props.otaUpdateSubmittingWithSpinner &&
    `
    background: ${midnight};
    color: ${midnight};
    cursor: not-allowed;
    text-align: center;
    padding: 5px;
    margin-right: 0px;
    min-width: 140px;
    font-size: 11px;
    &:hover {
			color: ${midnight};
    }
    `}

${(props) =>
    props.permissions &&
    `
      background: ${midnight};
      color: ${cotton};
      text-align: center;
      padding: 10px 31px;
      min-width: 180px;
      padding: 10px 31px;
      min-width: 180px;
      font-size: 11px;
      `}

${(props) =>
    props.disabledStyleProp &&
    `
    opacity:.5;
    pointer-events: none;
    `}

  ${(props) =>
    props.query &&
    `
      background: ${midnight};
      color: ${cotton};
      text-align: center;
      margin-right: 20px;
      padding: 10px 31px;
      min-width: 180px;
      font-size: 11px;

    `}

${(props) =>
    props.geofences &&
    `
      background: ${midnight};
      color: ${cotton};
      text-align: center;
      padding: 10px 31px;
      min-width: 128px;
      padding: 10px 31px;
      min-width: 180px;
      font-size: 11px;

    `}

    ${(props) =>
    props.loginButton &&
    `
      width: 340px;
      padding: 20px 15px;
      border: none;
      border-radius: 30px;
      background: ${colors.midnight};
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.13em;
      text-transform: uppercase;
      color: ${cotton};
      transition: background 0.3s;

      @media ${device.large} {
        width: 190px;
        padding: 9px 15px;
        border-radius: 17px;
        font-size: 11px;
        border: none;
      }

    &:hover {
      background: ${sky};
    }
    `}

    ${(props) =>
    props.loginWithSpinner &&
    `
      width: 340px;
      border-radius: 30px;
      background: ${midnight};
      color: ${midnight};
      cursor: not-allowed;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.13em;
      text-transform: uppercase;
      text-align: center;
      padding: 20px 15px;
      border: none;

      @media ${device.large} {
        width: 190px;
        padding: 9px 15px;
        border-radius: 17px;
        font-size: 11px;
        border: none;
      }

      &:hover {
        color: ${midnight};
      }
      `}

${(props) =>
    props.formConfirm &&
    `
		padding: 10px 31px;
    background-color: #FFF;
    font-size: 12px;
    color: ${midnight};
    border-color: ${midnight};
    border-width: 2px;
    border-style: solid;
    transition: background .3s, color .3s;
    min-width: 180px;

    &:hover {
      background-color: ${midnight};
      color: white;
    }
    `}

${(props) =>
    props.updates &&
    `
      background: ${midnight};
      color: ${cotton};
      text-align: center;
      padding: 10px 31px;
      min-width: 180px;
      font-size: 11px;
    `}

${(props) =>
    props.simpleOk &&
    `
    min-width:100px;
    `}
`;

export const Button = styled.button`
  position: relative;
  display: inline-block;
  padding: 10px 31px;
  border: none;
  border-radius: 21px;
  cursor: pointer;
  background-color: ${midnight};
  color: ${cotton};
  font-weight: 800;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  ${(props) =>
    props.formConfirm &&
    `
		padding: 10px 31px;
    background-color: #FFF;
    font-size: 12px;
    color: ${midnight};
    border-color: ${midnight};
    border-width: 2px;
    border-style: solid;
    transition: background .3s, color .3s;

    &:hover {
      background-color: ${midnight};
      color: white;
    }
    `}

  ${(props) =>
    props.formCancel &&
    `
		padding: 10px 31px;
    background-color: #FFF;
    font-size: 12px;
    color: ${haze};
    border-color: ${haze};
    border-width: 2px;
    border-style: solid;
    transition: background .3s, color .3s;

    &:hover {
      background-color: ${haze};
      color: ${cotton};
    }
    `};

  ${(props) =>
    props.exitButton &&
    `
    padding: 8px 20px;
    margin-right:10px;
    font-size: 12px;
    color: ${cotton};
    background-color: ${cherry};
    display:none;
    `}
  ${(props) =>
    props.exitButtonIsVisible &&
    `
    display:block;
    `}
`;

export const ButtonWithSpinnerContainer = styled.div`
  display: inline-block;
  position: relative;

  ${(props) =>
    props.inherit &&
    `
    display: inherit;
    `}
`;

export const ActionButtonWithIcon = styled.button`
  position: relative;
  display: block;
  border: none;
  height: 34px;
  border-radius: 17px;
  background: ${colors.midnight};
  padding: 10px 42px 10px 14px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.11em;
  color: ${colors.cotton};
  transition: background 0.3s;

  &:hover {
    background: ${colors.charcoal};
    cursor: pointer;
  }

  .svg-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${(props) =>
    props.orgDetail &&
    `
      margin-right: 20px;
      padding: 10px 14px;
      min-width: 180px;
      margin-bottom: 10px;

      @media ${device.large} {
        margin-right: 20px;
        min-width: 0px;
      }

  `}
  ${(props) =>
    props.orgEdit &&
    `
    padding: 10px 25px;
  `}

  ${(props) =>
    props.create &&
    `
        padding: 10px 45px 10px 40px;
  `}

  ${(props) =>
    props.groupDetail &&
    `
    min-width: 160px;
  `}

    ${(props) =>
    props.integrations &&
    `
    min-width: 300px;
  `}

${(props) =>
    props.analytics &&
    `
      margin-right: 20px;
      padding: 10px 42px ;
      min-width: 180px;

      @media ${device.large} {
        margin-right: 20px;
        min-width: 180px;
      }

  `}

${(props) =>
    props.export &&
    `
  display:none;
  @media ${device.large} {
    margin-right: 20px;
      padding: 10px 42px;
      min-width: 200px;
      display:block;
      }

  `}

${(props) =>
    props.noIcon &&
    `
    margin-left: 0px;
    margin-right: 0px;
      padding: 10px 22px;
      min-width: 200px;
      display:block;

      @media ${device.medium} {
        margin-left: 20px;
        padding: 10px 42px;
      min-width: 200px;
      display:block;
      }
  `}

  ${(props) =>
    props.logsExport &&
    `

  display:none;
  @media ${device.largeAnalytics} {
    margin-right: 0px;
      padding: 10px 42px;
      width: 200px;
      height:38px;
      margin-top:-10px;
      justify-self:end;
      display:block;
      }

  `}
`;

export const ActionButtonText = styled.span`
  text-decoration: none;
`;

export const TextButton = styled.button`
  margin-top: 15px;
  margin-bottom: 15px;
  border: none;
  height: 20px;
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.midnight};

  @media ${device.large} {
    font-size: 11px;
  }

  &:hover {
    color: ${colors.charcoal};
    cursor: pointer;
  }

  ${(props) =>
    props.mapCard &&
    `
  margin-top: -5px;
  margin-bottom: 10px;
  float:right;

  @media ${device.large} {
    font-size: 14px;
  }

  @media all and (display-mode: fullscreen) {
    display:none;
}
  `}
  ${(props) =>
    props.largeFont &&
    `

  @media ${device.large} {
    font-size: 14px;
  }

  `}
   ${(props) =>
    props.maintenance &&
    `
     margin-left: 5px;
  @media ${device.large} {
    font-size: 12px;
  }

  `}

${(props) =>
    props.maintenanceMapCard &&
    `
    margin-top:0px;
    margin-bottom:0px;
    margin-left:5px;
    font-size: 10px;
    font-weight:700;

  `}
  ${(props) =>
    props.topSpeed &&
    `

  @media ${device.large} {
    font-size: 16px;
  }

  `}

${(props) =>
    props.vehicleNames &&
    `
  margin-left:20px;
  @media ${device.large} {
    font-size: 11px;
  }

  `}


${(props) =>
    props.inlineWithSearch &&
    `
  margin-top: 5px;
  margin-bottom: 0px;

  @media ${device.large} {
    font-size: 14px;
  font-weight: 700;
  }
  `}

${(props) =>
    props.releaseNotes &&
    `
  text-transform: none;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 10px;
  @media ${device.large} {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  `}
`;

export const VehicleEditVinLookupButton = styled.button`
  ${vehicleEditVinLookupIconStyle}
`;

export const VehicleEditQRScannerButton = styled.button`
  ${vehicleEditVinLookupIconStyle}
`;
export const PermissionsHeaderButtonDiv = styled.div`
  margin-left: 5px;
  width: 185px;
  margin-bottom: 10px;
  ${(props) =>
    props.cancel &&
    `
    width: 128px;

  `}

  @media ${device.large} {
    margin-left: 20px;
  }
`;

export const RemoveButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  margin-left: 10px;
  margin-top: -2px;

  ${(props) =>
    props.lineItem &&
    `
    margin-left: 0px;
    margin-top: 0px;
    height:38px;
    display: none;
    @media ${device.large} {
      height:22px;
      display: block;
    }
  `}

  ${(props) =>
    props.nonPrimary &&
    `
    margin-left: 5px;
    margin-top: 2px;
  `}
`;

export const AddGroupButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 5px;
`;

export const BlueButton = styled.button`
  display: block;
  position: relative;
  padding: 6px 15px 6px 15px;
  text-decoration: none;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.59px;
  color: ${cotton};
  background: ${blueberry};
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;
  min-width: 90px;
  margin-top: 0px;
  height: 28px;

  ${(props) =>
    props.addRecipient &&
    `
    padding: 6px 15px 6px 25px;
    .svg-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  `}
  ${(props) =>
    props.addNotification &&
    `
    padding: 6px 15px 6px 25px;
    margin-top:20px;
    .svg-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  `}
  ${(props) =>
    props.groupRecipient &&
    `
    margin-top:25px;
  `}

${(props) =>
    props.matchButton &&
    `
  padding: 0px;
  min-width: 10px;
  width:48px;
  font-size: 11px;
  font-weight: 700;
  height: 18px;
  border-radius: 3px;
  `}
`;
