import {
  StyledDivModalContent,
  StyledModalText,
} from 'legacy/shared/controls/WcpModal/v2/styles/WcpModalStyles';
import { StyledBoldedText } from 'legacy/shared/styles/v2/Formatting';

const ReceiveGttAccessKeyModalContent = ({ modalState: { gttAccessKey } }) => {
  return (
    <StyledDivModalContent>
      <StyledModalText>
        This is your one time access key. Please make sure to copy it for use in the Miovision cloud
      </StyledModalText>
      <StyledBoldedText>{gttAccessKey}</StyledBoldedText>
    </StyledDivModalContent>
  );
};

export default ReceiveGttAccessKeyModalContent;
