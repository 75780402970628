import { useEffect, useRef, useState } from 'react';

import useClickOutsideContainerHandler from 'legacy/shared/utilities/hooks/useClickOutsideContainerHandler';
import { FormAsterisk, FormLabelAnimated } from 'legacy/shared/styles/custom/Form';
import FilteredDropdownBox from 'legacy/shared/controls/WcpFilteredDropdown/v4/FilteredDropdownBox';
import FilteredDropdownSearchBox from 'legacy/shared/controls/WcpFilteredDropdown/v4/FilteredDropdownSearchBox';
import DropdownOptions from 'legacy/shared/utilities/classes/dropdown/DropdownOptions';
import { SC_FilteredDropdown_div_Wrapper } from 'legacy/shared/controls/WcpFilteredDropdown/v4/styles/FilteredDropdownStyles';

//LATEST GREATEST FD
const FilteredDropdown = ({
  fieldLabel,
  placeholderLabel = 'Make a selection',
  styleOptions: {
    disabled = false,
    showClearSelectionButton = true,
    restrictHeight = true,
    required = false,
    narrowWidth = false,
    fullWidth = false,
  } = {},
  behaviorOptions: {
    clearOnSelection = false,
    closeOnSelect = false,
    disableSort = false,
    disableSearch = false,
  } = {},
  setup: {
    dropdownOptions = new DropdownOptions(),
    filterFn,
    decorateLabels,
    // default sort: alpha by label
    sortFn = (a, b) => a.label.localeCompare(b.label),
    preselectedValue = null,
    preselectedOption = null,
    filterDependencies = [],
  } = {},
  eventHandlers: { handleSelect = () => {}, handleItemHover = () => {} } = {},

  domId,
}) => {
  // set the sort function to a no-op function if disableSort is true
  if (disableSort) sortFn = (x) => x;

  // validate the dropdownOptions
  if (!(dropdownOptions instanceof DropdownOptions)) {
    throw new Error(
      `dropdownOptions must be an instance of DropdownOptions but received ${typeof dropdownOptions}`,
    );
  }

  const previouslySelectedValueRef = useRef(null); // the previously selected value
  const [optionsSortedAndFiltered, setOptionsSortedAndFiltered] = useState(dropdownOptions); // the dropdown options sorted and filtered
  const [selectedOption, setSelectedOption] = useState(null); // the currently selected option { label, value }
  const [isOpen, setIsOpen] = useState(false); // whether the dropdown is open or closed
  const [searchText, setSearchText] = useState(''); // the search value in the search input
  const [hoveredItem, setHoveredItem] = useState(null); // the currently hovered item in the dropdown

  useEffect(() => {
    // must provide either preselectedValue or preselectedOption
    if (preselectedValue === undefined && preselectedOption === undefined) {
      throw new Error('preselectedValue or preselectedOption must be provided');
    }

    // if preselectedOption is provided, use it
    // if preselectedOption is not provided, use the preselectedValue to get the preselectedOption from the dropdownOptions
    // if that results in an 'undefined' option, set the selectedOption to null
    let localPreselectedOption =
      preselectedOption ||
      dropdownOptions.getOptionByValue({ value: preselectedValue, includeReadonly: true }) ||
      null;

    // a selected option was not found, set the selected option to null which will display the placeholder
    if (localPreselectedOption === null) {
      setSelectedOption(null);
      return;
    }

    setSelectedOption(localPreselectedOption);

    if (localPreselectedOption.value !== previouslySelectedValueRef.current) {
      previouslySelectedValueRef.current = preselectedValue;
    }
  }, [preselectedValue, preselectedOption]);

  useEffect(() => {
    let options = dropdownOptions.getAllOptionsSortedAndFiltered({ sortFn, filterFn });

    if (decorateLabels) {
      options.forEach((option) => {
        option.label = decorateLabels(option);
      });
    }

    setOptionsSortedAndFiltered(options);
  }, [dropdownOptions, ...filterDependencies]);

  useEffect(() => {
    // if no selected option, return
    if (!selectedOption) {
      previouslySelectedValueRef.current = null;
      return;
    }

    // call handleItemSelect handler if the selected item has changed
    if (selectedOption?.value !== previouslySelectedValueRef.current) {
      handleSelect(selectedOption);
    }

    if (clearOnSelection) {
      setSelectedOption(null);
      setHoveredItem(null);
      previouslySelectedValueRef.current = null;
    }

    if (closeOnSelect) {
      setIsOpen(false);
    }
  }, [selectedOption?.value]);

  // reset the search input when the dropdown is closed
  useEffect(() => {
    setSearchText('');
  }, [isOpen]);

  // close the dropdown when clicking outside the dropdown container
  const containerRef = useClickOutsideContainerHandler({
    handler: () => {
      setIsOpen(false);
    },
  });

  return (
    <SC_FilteredDropdown_div_Wrapper
      ref={containerRef}
      disabled={disabled}
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      id={domId}
    >
      {/* TODO - move this out to use WcpFilteredDropdownField properly (see WcpTextInput) */}
      <FormLabelAnimated animated>
        {fieldLabel}
        {required && <FormAsterisk>*</FormAsterisk>}
      </FormLabelAnimated>
      <div>
        <FilteredDropdownBox
          narrowWidth={narrowWidth}
          showClearSelectionButton={showClearSelectionButton}
          fullWidth={fullWidth}
          placeholderLabel={placeholderLabel}
          selectedOption={clearOnSelection ? '' : selectedOption}
          onClearFilter={() => {
            handleSelect({ label: placeholderLabel, value: null });
            setSelectedOption(null);
          }}
        />
        {isOpen && (
          <FilteredDropdownSearchBox
            disableSearch={disableSearch}
            restrictHeight={restrictHeight}
            fullWidth={fullWidth}
            searchText={searchText}
            setSearchText={setSearchText}
            options={optionsSortedAndFiltered}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
            optionsDomId={`${domId}-options`}
          />
        )}
      </div>
    </SC_FilteredDropdown_div_Wrapper>
  );
};
export default FilteredDropdown;
