import { modalContracts } from 'legacy/features/organizations/providers/modal/constants/modalContracts';

const useWcpModalContracts = (...modalContractKeys) => {
  if (modalContractKeys.length) {
    return modalContracts.filter((contract) => modalContractKeys.includes(contract.modalKey));
  } else {
    return modalContracts;
  }
};

export default useWcpModalContracts;
