import AssociateFseModalContent from 'legacy/features/organizations/components/modals/AssociateFseModal/AssociateFseModalContent';
import AssociateFseModalControls from 'legacy/features/organizations/components/modals/AssociateFseModal/AssociateFseModalControls';
import CreateOrganizationModalContent from 'legacy/features/organizations/components/modals/CreateOrganizationModal/CreateOrganizationModalContent';
import CreateUserModalContent from 'legacy/features/organizations/components/modals/CreateUserModal/CreateUserModalContent';
import DeactivateOrganizationConfirmationModalContent from 'legacy/features/organizations/components/modals/DeactivateOrganizationModal/DeactivateOrganizationConfirmationModalContent';
import DeactivateOrganizationConfirmationModalControls from 'legacy/features/organizations/components/modals/DeactivateOrganizationModal/DeactivateOrganizationConfirmationModalControls';
import { DeactivateUserConfirmationModalContent } from 'legacy/features/organizations/components/modals/DeactivateUserModal/DeactivateUserConfirmationModalContent';
import DeactivateUserConfirmationModalControls from 'legacy/features/organizations/components/modals/DeactivateUserModal/DeactivateUserConfirmationModalControls';
import EditOrganizationModalContent from 'legacy/features/organizations/components/modals/EditOrganizationModal/EditOrganizationModalContent';
import EditUserModalContent from 'legacy/features/organizations/components/modals/EditUserModal/EditUserModalContent';
import ReceiveGttAccessKeyModalContent from 'legacy/features/organizations/components/modals/ReceiveGttAccessKeyModal/ReceiveGttAccessKeyModalContent';
import ReceiveGttAccessKeyModalControls from 'legacy/features/organizations/components/modals/ReceiveGttAccessKeyModal/ReceiveGttAccessKeyModalControls';
import RegenerateGttAccessKeyModalContent from 'legacy/features/organizations/components/modals/RegenerateGttAccessKeyModal/RegenerateGttAccessKeyModalContent';
import RegenerateGttAccessKeyModalControls from 'legacy/features/organizations/components/modals/RegenerateGttAccessKeyModal/RegenerateGttAccessKeyModalControls';
import {
  CREATE_ORGANIZATION_MODAL_KEY,
  EDIT_ORGANIZATION_MODAL_KEY,
  DEACTIVATE_ORGANIZATION_MODAL_KEY,
  CREATE_USER_MODAL_KEY,
  EDIT_USER_MODAL_KEY,
  DEACTIVATE_USER_CONFIRMATION_MODAL_KEY,
  ASSOCIATE_FSE_MODAL_KEY,
  RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
  REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
} from 'legacy/features/organizations/providers/modal/constants/modalKeys';
import { WcpSvgIconFactory } from 'legacy/shared/controls/WcpIcon/v2/WcpSvgIcon';

export const modalContracts = [
  // Organization modals
  {
    modalKey: CREATE_ORGANIZATION_MODAL_KEY,
    modalTitle: 'Create Organization',
    modalIcon: WcpSvgIconFactory.PLUS,
    modalContentComponent: CreateOrganizationModalContent,
    // modalControlsComponent: default
    modalStyleOptions: {
      width: '800px',
    },
    modalContentStyleOptions: {
      marginTop: '30px',
    },
  },
  {
    modalKey: EDIT_ORGANIZATION_MODAL_KEY,
    modalTitle: 'Edit Organization',
    modalIcon: WcpSvgIconFactory.EDIT_PENCIL_BLUE,
    modalContentComponent: EditOrganizationModalContent,
    // modalControlsComponent: default
    modalStyleOptions: {
      width: '750px',
    },
    modalContentStyleOptions: {
      marginTop: '30px',
    },
  },
  {
    modalKey: DEACTIVATE_ORGANIZATION_MODAL_KEY,
    modalTitle: 'Deactivate Organization',
    modalContentComponent: DeactivateOrganizationConfirmationModalContent,
    modalControlsComponent: DeactivateOrganizationConfirmationModalControls,
    modalStyleOptions: {
      width: '680px',
    },
  },
  // User modals
  {
    modalKey: CREATE_USER_MODAL_KEY,
    modalTitle: 'Create User',
    modalIcon: WcpSvgIconFactory.PLUS,
    modalContentComponent: CreateUserModalContent,
    // modalControlsComponent: default
    modalStyleOptions: {
      width: '720px',
    },
  },
  {
    modalKey: EDIT_USER_MODAL_KEY,
    modalTitle: 'Edit User',
    modalIcon: WcpSvgIconFactory.EDIT_PENCIL_BLUE,
    modalContentComponent: EditUserModalContent,
    // modalControlsComponent: default
    modalStyleOptions: {
      width: '720px',
    },
  },
  {
    modalKey: DEACTIVATE_USER_CONFIRMATION_MODAL_KEY,
    modalTitle: 'Deactivate User',
    modalContentComponent: DeactivateUserConfirmationModalContent,
    modalControlsComponent: DeactivateUserConfirmationModalControls,
  },
  {
    modalKey: ASSOCIATE_FSE_MODAL_KEY,
    modalTitle: 'Add Field Solution Engineer',
    modalIcon: WcpSvgIconFactory.PLUS,
    modalContentComponent: AssociateFseModalContent,
    modalControlsComponent: AssociateFseModalControls,
    modalStyleOptions: {
      width: '950px',
      height: '900px',
    },
  },
  // GTT modals
  {
    modalKey: RECEIVE_GTT_ACCESS_KEY_MODAL_KEY,
    modalTitle: 'Miovision Access Key',
    modalContentComponent: ReceiveGttAccessKeyModalContent,
    modalControlsComponent: ReceiveGttAccessKeyModalControls,
    modalStyleOptions: {
      width: '520px',
    },
  },
  {
    modalKey: REGENERATE_GTT_ACCESS_KEY_MODAL_KEY,
    modalTitle: 'Generate New Key',
    modalContentComponent: RegenerateGttAccessKeyModalContent,
    modalControlsComponent: RegenerateGttAccessKeyModalControls,
    modalStyleOptions: {
      width: '520px',
    },
  },
];
