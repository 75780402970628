import { SimpleDropdownBtn, SimpleDropdownItem } from 'legacy/shared/styles/custom/DropdownMenu';

const GenerateGttKeyMenuItem = ({ partnership, onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Generate Miovision Access Key</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default GenerateGttKeyMenuItem;
