import { getAuthToken, apiCall } from 'legacy/core/api/API';
import {
  getStartOfDayForDateTimeInUtc,
  getEndOfDayForDateTimeInUtc,
  getEndOfMonthDateStringForDateTime,
  getStartOfMonthDateStringForDateTime,
} from 'legacy/shared/utilities/misc/time';

export const getDashboardAnalytics = async ({
  startDate,
  endDate,
  groupId,
  vehicleId,
  organizationId,
}) => {
  return await apiCall({
    path: `/analytics/summary/`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },

    queryStringParameters: {
      start_date: startDate,
      end_date: endDate,
      group_id: groupId,
      vehicle_id: vehicleId,
      organization_id: organizationId,
    },
  });
};

export const getLogsAnalytics = async ({
  startDate,
  endDate,
  groupId,
  vehicleId,
  organizationId,
}) => {
  return await apiCall({
    path: `/analytics/summary/${vehicleId}`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },

    queryStringParameters: {
      start_date: startDate,
      end_date: endDate,
      group_id: groupId,
      vehicle_id: vehicleId,
      organization_id: organizationId,
    },
  });
};

export const getHeatmapData = async ({ startDate, endDate, organizationId }) => {
  return await apiCall({
    path: `/analytics/heatmap`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      start_date: startDate,
      end_date: endDate,
      organization_id: organizationId,
      version: 2,
    },
  });
};
