import {
  StyledDivReadOnlyFormLabel,
  StyledLabelAnimated,
} from 'legacy/shared/controls/WcpForm/v2/styles/WcpFieldStyles';
import { TruncatedText } from 'legacy/shared/styles/custom/TruncatedText';
import React from 'react';

const WcpReadOnlyFormLabel = ({ label, value }) => {
  return (
    <StyledDivReadOnlyFormLabel>
      <StyledLabelAnimated performLabelTransition>{label}</StyledLabelAnimated>
      <TruncatedText title={value}>{value}</TruncatedText>
    </StyledDivReadOnlyFormLabel>
  );
};

export default WcpReadOnlyFormLabel;
