import { apiCall } from 'legacy/core/api/API';
import { getFirmwareChannelsPath } from 'legacy/shared/constants/api';
import {
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
} from 'legacy/shared/constants/vehicle';
/*
 * @ Decorators
 */

export const getVehicleStatusCell = (vehicle) => {
  if (vehicle.onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id) {
    if (vehicle.respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id) {
      return <span className={'danger'}>{vehicleRespondingStatusViewData.RESPONDING.title}</span>;
    }
    return <span className={'success'}>{vehicleOnlineStatusViewData.ACTIVE.title}</span>;
  } else {
    return <span>{vehicleOnlineStatusViewData.INACTIVE.title}</span>;
  }
};

/*
 * @ Services
 */

// this one called by sidebar and update summary page (update by device tab)
export const getFirmwares = async ({ organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/firmware/update',
    method: 'get',
    queryStringParameters: {
      include_completed: true,
      ...(organizationId ? { organization_id: organizationId } : {}),
      version: 3,
    },
  });
};

export const getFirmwaresByVehicle = async ({ organizationId }) => {
  console.log('getFirmwaresByVehicle');
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/firmware/update/vehicles',
    method: 'get',
    queryStringParameters: {
      ...(organizationId ? { organization_id: organizationId } : {}),
      version: 3,
    },
  });
};

export const getFirmwaresBySingleVehicle = async ({ organizationId, vehicleId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/firmware/update/vehicles/${vehicleId}`,
    method: 'get',
    queryStringParameters: {
      ...(organizationId ? { organization_id: organizationId } : {}),
      version: 3,
    },
  });
};

export const getFirmwareOtaLogs = async ({ organizationId, startDate, endDate }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/firmware/logs/',
    method: 'get',
    queryStringParameters: {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getFirmwareChannels = async (productId) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${getFirmwareChannelsPath}/${productId}`,
    method: 'get',
  });
};

export const checkUpdates = async (organizationId) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/firmware/check`,
    method: 'post',
    queryStringParameters: {
      ...(organizationId ? { organization_id: organizationId } : {}),
    },
  });
};

export const updateFirmware = async ({
  vehicleId,
  productId,
  updateChannel,
  updateVersion,
  devicesToUpdate,
  organizationId,
}) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/firmware/update/${productId}`,
    method: 'post',
    queryStringParameters: {
      organization_id: organizationId,
      version: 2,
    },
    body: {
      authorized_version: updateVersion,
      authorized_channel: updateChannel,
      devices: devicesToUpdate.map((d) => ({
        vehicle_id: vehicleId || d.vehicleId,
        //by device page sends deviceId, by vehicle sends device_id
        device_id: d.deviceId || d.device_id,
      })),
    },
  });
};

// single vehicle update
export const updateAllFirmwareForSingleVehicle = async ({ vehicleId, organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/firmware/update/vehicles/${vehicleId}`,
    method: 'post',
    queryStringParameters: {
      organization_id: organizationId,
    },
  });
};
