import RecipientRowsListContainer from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/containers/RecipientRowsListContainer';
import AdditionalNotificationHeader from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/headers/AdditionalNotificationHeader';
import IncludeLocationCheckbox from 'legacy/features/alerts/ui/components/AlertForms/shared/checkboxes/IncludeLocationCheckbox';
import CooldownPeriodDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/CooldownPeriodDropdown';
import DelayDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/DelayDropdown';
import {
  DelayCooldownWrapper,
  RecipientHeaderWrapper,
} from 'legacy/shared/styles/custom/ManageAlerts';

const AdditionalNotificationContainer = ({ notificationIndex, domId }) => {
  return (
    <div id={domId}>
      <RecipientHeaderWrapper>
        <AdditionalNotificationHeader notificationIndex={notificationIndex} />
      </RecipientHeaderWrapper>
      <RecipientRowsListContainer notificationIndex={notificationIndex} />
      <DelayCooldownWrapper>
        <DelayDropdown notificationIndex={notificationIndex} />
        <CooldownPeriodDropdown notificationIndex={notificationIndex} />
      </DelayCooldownWrapper>
      <IncludeLocationCheckbox notificationIndex={notificationIndex} />
    </div>
  );
};

export default AdditionalNotificationContainer;
