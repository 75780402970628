import {
  SC_FilteredDropdown_Popup_div,
  SC_FilteredDropdown_Search_input,
  SC_FilteredDropdown_SearchResult_li,
  SC_FilteredDropdown_SearchResults_ul,
} from 'legacy/shared/controls/WcpFilteredDropdown/v4/styles/FilteredDropdownStyles';
import {
  findStartingIndex,
  highlightSearchText,
} from 'legacy/shared/utilities/helpers/formatting/highlightSearchText';
import { TruncatedTextWithWrap } from 'legacy/shared/styles/custom/TruncatedText';
import IconSvgComponent from 'legacy/shared/controls/WcpIcon/IconSvgComponent';

const FilteredDropdownSearchBox = ({
  restrictHeight,
  options,
  searchText,
  hoveredItem,
  setSearchText,
  setHoveredItem,
  setSelectedOption,
  fullWidth,
  disableSearch,
  optionsDomId,
}) => {
  return (
    <SC_FilteredDropdown_Popup_div hideSearch={disableSearch} fullWidth={fullWidth}>
      {!disableSearch && (
        <IconSvgComponent
          svgStyle={{
            height: '22px',
            width: '22px',
            marginRight: '10px',
          }}
          svgFileName={'search'}
          alt="Search"
        />
      )}
      <div>
        {!disableSearch && (
          <SC_FilteredDropdown_Search_input
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus
            value={searchText}
            fullWidth={fullWidth}
          />
        )}
        <div>
          <SC_FilteredDropdown_SearchResults_ul
            hideSearch={disableSearch}
            restrictHeight={restrictHeight}
            fullWidth={fullWidth}
            id={optionsDomId}
          >
            {options.map((item, index) =>
              searchText === '' || findStartingIndex(searchText, item.label) > -1 ? (
                <SC_FilteredDropdown_SearchResult_li
                  key={`${item.value}${index}`}
                  highlight={index === hoveredItem}
                  showTopBorder={index === 0}
                  hideSearch={disableSearch}
                  onMouseEnter={() => setHoveredItem(index)}
                  onClick={() => setSelectedOption(item)}
                >
                  <TruncatedTextWithWrap title={item.label}>
                    {highlightSearchText(searchText, item.label)}
                  </TruncatedTextWithWrap>
                </SC_FilteredDropdown_SearchResult_li>
              ) : null,
            )}
          </SC_FilteredDropdown_SearchResults_ul>
        </div>
      </div>
      {/* TODO Create New Item Option */}
    </SC_FilteredDropdown_Popup_div>
  );
};

export default FilteredDropdownSearchBox;
