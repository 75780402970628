import {
  alphanumericExtendedRegex,
  phoneRegex,
  phoneExtensionRegex,
  wimAccountIdRegex,
} from 'legacy/shared/utilities/helpers/validation/validationRegularExpressions';
import {
  alphanumericExtendedRegexError,
  emailRegexError,
  phoneRegexError,
  phoneExtensionRegexError,
  requiredError,
  wimAccountIdRegexError,
} from 'legacy/shared/utilities/helpers/validation/validationErrorMessages';
import { object, string } from 'yup';

export const organizationValidationSchema = object({
  organizationName: string()
    .required(requiredError)
    .max(128, 'Please enter 128 characters or less')
    .matches(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  contactName: string()
    .required(requiredError)
    .max(128, 'Please enter 128 characters or less')
    .matches(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  contactPhone: string().required(requiredError).matches(phoneRegex, phoneRegexError),
  contactPhoneExtension: string().matches(phoneExtensionRegex, phoneExtensionRegexError).nullable(),
  contactEmail: string().required(requiredError).email(emailRegexError),
  address1: string()
    .required(requiredError)
    .matches(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  address2: string().matches(alphanumericExtendedRegex, alphanumericExtendedRegexError).nullable(),
  city: string()
    .required(requiredError)
    .matches(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  wimAccountId: string().required(requiredError).matches(wimAccountIdRegex, wimAccountIdRegexError),
  addressState: string().required(requiredError),
  customerType: string().required(requiredError),
  timeZone: string().required(requiredError),
});
