import { EMPTY_STRING } from 'legacy/shared/constants/api';

export const userMapping = {
  Username: {
    frontendProperty: 'username',
  },
  email: {
    frontendProperty: 'email',
  },
  phone_number: {
    frontendProperty: 'phoneNumber',
  },
  given_name: {
    frontendProperty: 'firstName',
  },
  family_name: {
    frontendProperty: 'lastName',
  },
  user_title: {
    frontendProperty: 'userTitle',
  },
  active: {
    frontendProperty: 'isActive',
  },
  user_role: {
    frontendProperty: 'userRole',
  },
  picture: {
    frontendProperty: 'avatar',
    transformValueToFrontend: (value) => {
      return value === EMPTY_STRING ? null : value;
    },
    transformValueToBackend: (value) => {
      return value ? value : EMPTY_STRING;
    },
  },
  organization_id: {
    frontendProperty: 'organizationId',
  },
  two_factor_auth_method: {
    frontendProperty: 'mfaMethod',
  },
  permissions: {
    frontendProperty: 'userPermissions',
  },
  opt_out_of_emails: {
    frontendProperty: 'optOutOfEmails',
  },
  reset_user: {
    frontendProperty: 'resetUser',
  },
};
