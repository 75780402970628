import Button from 'legacy/shared/styles/custom/Button';

const WcpFormCancelButton = ({ handleClick }) => {
  return (
    <Button default type="button" withRightSpacer={true} onClick={handleClick}>
      Cancel
    </Button>
  );
};
export default WcpFormCancelButton;
