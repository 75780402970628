import WcpCheckbox from 'legacy/shared/controls/WcpCheckbox/v1/WcpCheckbox';
import useWcpFormContext from 'legacy/shared/controls/WcpForm/v2/hooks/useWcpFormContext';
import React from 'react';

const WcpCheckboxField = ({
  dataKey,
  label = 'CHANGE ME!',
  disabled = false,
  inverted = false,
  handleChange,
  handleBlur,
  handleFocus,
}) => {
  if (!dataKey) {
    throw new Error(`dataKey prop is required for the field with label "${label}"`);
  }

  const { formValues, setFormValues, validationSchema, validationErrors, validate } =
    useWcpFormContext();

  return (
    <WcpCheckbox
      label={label}
      disabled={disabled}
      checked={inverted ? !formValues?.[dataKey] : formValues?.[dataKey]}
      handleChange={() => {
        setFormValues({ [dataKey]: !formValues?.[dataKey] });
        handleChange && handleChange(!formValues?.[dataKey]);
      }}
    />
  );
};

export default WcpCheckboxField;
